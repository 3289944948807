<template>
  <div>
    <a-modal
      v-model="visibleDownloadModal"
      title="Download Selected Student's Attachment Files"
    >
      <a-table
        :columns="selectedColumns"
        :data-source="selectedRow"
        :scroll="{ y: 300 }"
        :pagination="{ pageSize: 30, hideOnSinglePage: true }"
        bordered
      >
        <div class="d-flex justify-content-end" slot="footer" slot-scope="data">
          Total Student: {{ data.length }}
        </div>
        <div
          slot="student"
          slot-scope="value, record"
          class="d-flex flex-column"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="font-weight-bold">{{record.nama}}</div>
              <div class="my-2 text-gray-5">NIS {{record.nis || '-'}}</div>
              <div class="text-gray-5">{{record.tahun_masuk || '-'}}</div>
            </div>
            <div>{{record.dataLampiranPpdb ? record.dataLampiranPpdb.length : 0}} file(s)</div>
          </div>
        </div>
      </a-table>
      <template slot="footer">
        <a-button size="large" key="back" @click="visibleDownloadModal = false">
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          @click="downloadFile('checklist')"
        >
          Download Now
        </a-button>
      </template>
    </a-modal>
    <a-modal
      width="70em"
      v-model="visibleModalManagePending"
      class="mod"
      :title="
        status.isEdit
          ? 'Form Edit Pending'
          : status.isDetail
          ? 'Detail Student Candidate'
          : 'Form Create Pending'
      "
      @cancel="toggleModalManagePending"
      centered
    >
      <template v-if="status.isDetail">
        <div
          v-if="detailLoading"
          style="
            display: flex;
            justify-content: center;
            height: 30vh;
            align-items: center;
          "
        >
          <a-spin>
            <a-icon
              slot="indicator"
              type="loading"
              style="font-size: 5em; color: var(--kit-color-primary)"
              spin
            />
          </a-spin>
        </div>
        <div v-else>
          <detail-student-list :data="detailData" isEditable @handle-change="handleChange"/>
        </div>
      </template>
      <!-- <form-pending :dataPending="newPending" @handle-change="handleChange" /> -->
      <template slot="footer">
        <template v-if="status.isDetail">
          <a-button
            @click="toggleModalManagePending"
            type="default"
            size="large"
            key="back"
            >Close</a-button
          >
          <a-button
            @click="handleUpdateData"
            :loading="loadingActionModalManagePending"
            type="primary"
            size="large"
            key="submit"
            >Update Now</a-button
          >
          <!-- <a-button
            @click="handlePendingAction('reject', detailData, true)"
            type="danger"
            size="large"
            key="back"
            >Reject</a-button
          >
          <a-button
            @click="handlePendingAction('approve', detailData, true)"
            type="primary"
            size="large"
            key="submit"
            >Approve</a-button
          > -->
          <!-- <a-button class="d-none" key="back"> Return </a-button>
          <a-button
            key="submit"
            type="default"
            @click="toggleModalManagePending"
          >
            Close
          </a-button> -->
        </template>
        <template v-else>
          <a-button size="large" key="back" @click="toggleModalManagePending">
            Cancel
          </a-button>
          <a-button
            size="large"
            key="submit"
            type="primary"
            :loading="loadingActionModalManagePending"
            @click="handleOkModalManagePending"
          >
            {{ status.isEdit ? "Edit" : "Create" }} Now
          </a-button>
        </template>
      </template>
    </a-modal>
    <a-modal
      :title="titlePendingAction"
      :visible="visiblePendingAction"
      :confirm-loading="confirmLoadingPendingAction"
      :destroyOnClose="true"
      :okText="
        status.isApprove ? 'Approve Now' : status.isReject ? 'Reject Now' : ''
      "
      :okType="status.isApprove ? 'primary' : status.isReject ? 'danger' : ''"
      :ok-button-props="{
        props: {
          disabled: status.isApprove
            ? !newDataPendingAction.nis || !newDataPendingAction.keterangan_ppdb
            : status.isReject
            ? !newDataPendingAction.keterangan_ppdb
            : true,
        },
      }"
      @ok="handleOkPendingAction"
      @cancel="handleTogglePendingAction"
    >
      <template v-if="status.isApprove">
        <div>
          <h3>Are you sure want to approve {{ dataPendingAction.nama }}?</h3>
          <p class="mt-2">Please fill the NIS and the reason why approved</p>
          <hr />
          <a-form-item label="NIS">
            <a-input
              v-model="newDataPendingAction.nis"
              class="w-100"
              size="large"
            />
          </a-form-item>
          <a-form-item label="Reason Approved">
            <a-input
              v-model="newDataPendingAction.keterangan_ppdb"
              class="w-100"
              size="large"
            />
          </a-form-item>
        </div>
      </template>
      <template v-if="status.isReject">
        <div>
          <h3>Are you sure want to reject {{ dataPendingAction.nama }}?</h3>
          <p class="mt-2">Please fill the NIS and the reason why rejected</p>
          <hr />
          <a-form-item label="Reason Rejected">
            <a-input
              v-model="newDataPendingAction.keterangan_ppdb"
              class="w-100"
              size="large"
            />
          </a-form-item></div
      ></template>
    </a-modal>
    <!-- <div class="d-flex align-items-center mb-3">
      <div
        class="
          w-50
          border border-warning
          rounded
          px-3
          pb-3
          mr-3
          d-flex
          align-items-center
        "
      >
        <a-icon class="mt-3" :style="{ fontSize: '42px' }" type="container" />
        <div class="ml-4">
          <h1 class="">123</h1>
          <p class="text-uppercase">Total Pending Student</p>
        </div>
      </div>
      <div
        class="
          w-50
          border border-warning
          rounded
          px-3
          pb-3
          mr-3
          d-flex
          align-items-center
        "
      >
        <a-icon
          class="mt-3"
          :style="{ fontSize: '42px' }"
          type="clock-circle"
        />
        <div class="ml-4">
          <h1 class="">123</h1>
          <p class="text-uppercase">Today Pending Student</p>
        </div>
      </div>
    </div> -->

    <div class="d-flex align-items-center">
      <a-input
        class="w-25 mr-3"
        placeholder="Search Student"
        size="large"
        @change="handleSearch"
      />
      <a-select style="min-width: 150px;" size="large" v-model="yearBatch" @change="changeYear">
        <a-select-option value="">All Entry Year</a-select-option>
        <a-select-option v-for="(tahun, i) in tahunMasuk" :value="tahun.tahun" :key="i">{{tahun.tahun}}</a-select-option>
      </a-select>
      <div class="ml-auto">
        <a-dropdown>
          <a-menu slot="overlay">
            <a-menu-item key="1" @click="downloadFile('all')" class="p-3"> <a-icon type="file-zip" />All Student (by entry year)</a-menu-item>
            <a-menu-item :disabled="!selectedRow.length" key="2" @click="visibleDownloadModal = true" class="p-3"> <a-icon type="file-zip" />{{selectedRow.length}} students (by checklist)</a-menu-item>
          </a-menu>
          <a-button
            :disabled="loadingAttachmentDownload"
            class="mr-3"
            size="large"
            ><a-icon type="download" /> {{ loadingAttachmentDownload ? 'Downloading...' : 'Attachment Files (.zip)' }}<a-icon type="down" /></a-button
          >
        </a-dropdown>
        <a-button @click.prevent="exportExcel" :disabled="loadingExport" size="large" icon="database" type="primary">
          {{ loadingExport ? 'Exporting...' : 'Export List (.xlsx)' }}
        </a-button>
      </div>
      <!-- <div class="ml-auto">
        <a-button
          @click.prevent="toggleModalManagePending('add')"
          size="large"
          type="primary"
          ><a-icon type="plus" />Create Pending</a-button
        >
      </div> -->
    </div>
    <div class="mt-4">
      <a-table
        :row-selection="rowSelection"
        :columns="pendingColumns"
        :data-source="pendingDataTable"
        :pagination="pendingPagination"
        :loading="pendingLoadingTable"
        @change="handleTableChange"
        bordered
      >
        <div slot="nullStr" slot-scope="val">{{val || '-'}}</div>
        <div slot="action" slot-scope="value, record">
          <a-button
            @click.prevent="toggleModalManagePending('detail', record)"
            class="btn btn-outline-primary rounded mr-3"
            size="default"
          >
            <a-icon type="container" />Detail
          </a-button>
          <!-- <a-button
            @click.prevent="handlePendingAction('approve', record)"
            class="btn btn-outline-success rounded mr-3"
            size="default"
          >
            <a-icon type="check" />Approve
          </a-button>
          <a-button
            @click.prevent="handlePendingAction('reject', record)"
            class="btn btn-outline-danger rounded"
            size="default"
          >
            <a-icon type="close" />Reject
          </a-button> -->
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
// const formPending = () => import('@/components/app/Admin/Master/Form/Pending')
import { exportDataPpdbMurid } from '@/helpers/ledgerExcel.js'
import FileSaver from 'file-saver'
import JsZip from 'jszip'
import axios from 'axios'
const detailStudentList = () => import('@/components/app/shared/DetailStudentList')

const dummyZip = [
  {
    tahun: '2021',
    murids: [
      {
        nama: 'Sugeng',
        files: [
          {
            filepath: '/ppdb/ijazah/',
            filename: '1635232748557_1200px-Amethyst._Magaliesburg,_South_Africa.jpg',
            deskripsi_lampiran: 'ijazah',
            format: 'jpg',
          },
        ],
      },
      {
        nama: 'Waluyo',
        files: [
          {
            filepath: '/Topics/7/3/',
            filename: '1635403005919_Scan_20200306.pdf',
            deskripsi_lampiran: 'ijazah',
            format: 'pdf',
          },
        ],
      },
    ],
  },
]

const selectedColumns = [
  {
    title: 'List Student Selected',
    dataIndex: 'student',
    key: 'student',
    scopedSlots: { customRender: 'student' },
    width: 220,
  },
]

const pendingColumns = [
  {
    title: 'Student Name',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
    sorter: true,
  },
  {
    title: 'Entry Year',
    dataIndex: 'tahun_masuk',
    key: 'tahun_masuk',
    scopedSlots: { customRender: 'nullStr' },
    align: 'center',
  },
  {
    title: 'Submission Date',
    dataIndex: 'tanggal_pengisian_dapodik',
    key: 'tanggal_pengisian_dapodik',
    scopedSlots: { customRender: 'tanggal_pengisian_dapodik' },
    align: 'center',
    sorter: true,
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]
export default {
  name: 'Pending',
  components: {
    // formPending,
    detailStudentList,
  },
  data() {
    return {
      pendingColumns,
      visibleModalManagePending: false,
      loadingActionModalManagePending: false,
      loadingDeletePending: false,
      loadingApprove: false,
      pendingLoadingTable: false,
      detailLoading: false,
      pendingDataTable: [],
      pendingPagination: {},
      detailData: {},
      status: {
        isEdit: false,
        isCreate: false,
        isDetail: false,
        isApprove: false,
        isReject: false,
      },
      newPending: {
        nama: null,
      },
      idEdit: null,
      newDataPendingAction: {
        nis: null,
        keterangan_ppdb: null,
      },
      titlePendingAction: '',
      visiblePendingAction: false,
      confirmLoadingPendingAction: false,
      dataPendingAction: null,
      search: '',
      sortBy: '',
      order: 'ASC',
      loadingExport: false,
      yearBatch: '',
      tahunMasuk: [],
      selectedRow: [],
      loadingAttachmentDownload: false,
      selectedColumns,
      visibleDownloadModal: false,
      dataZip: dummyZip,
    }
  },
  methods: {
    changeYear() {
      this.selectedRow = []
      this.selectedRowKeys = []
      this.fetchDataPending()
    },
    async fetchFile(path) {
      try {
        const { data } = await axios(path, {
          method: 'get',
          responseType: 'blob',
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        })
        // const file = await res.text()
        // console.log(res)
        return new Promise((resolve) => resolve(data))
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async downloadFile(key) {
      try {
        this.loadingAttachmentDownload = true
        const payload = {
          tahunMasuk: this.yearBatch?.split(' - ')[0],
        }
        const namaInstitusi = this.institusi?.nama?.toUpperCase() || ''
        let title = `LAMPIRAN FILE MURID DITERIMA ${namaInstitusi}`
        if (this.yearBatch?.split(' - ')[0]) title += ` TAHUN ${this.yearBatch.split(' - ')[0]}`

        if (key === 'checklist') payload.studentList = this.rowSelectedKey

        const data = await this.$store.dispatch('master/FETCH_FILE_FOR_ZIP', payload)
        if (!data?.length) {
          return this.$notification.error({
            message: 'Fetching Failed',
            description: 'Sorry, your data is not ready yet. Please try again later.',
          })
        }
        const zip = new JsZip()
        for (let i = 0; i < data.length; i++) {
          const dataTahun = data[i]
          const tahun = zip.folder(dataTahun.tahun)
          for (let j = 0; j < dataTahun.students.length; j++) {
            const dataMurid = dataTahun.students[j]
            const murid = tahun.folder(dataMurid.nama)
            for (let k = 0; k < dataMurid.files.length; k++) {
              const dataFile = dataMurid.files[k]
              // const file = await this.fetchFile(`https://demo.hasanteknologi.id:3000/${dataFile.filepath}${dataFile.filename}`)
              const file = await this.fetchFile(`${process.env.VUE_APP_API_URL}/${dataFile.filepath}${dataFile.filename}`)
              const filenameSplit = dataFile.filename.split('.')
              const format = filenameSplit[filenameSplit.length - 1] || dataFile.format
              murid.file(`${dataFile.deskripsi_lampiran}.${format}`, file, { base64: true, binary: true })
            }
          }
        }
        const blob = await zip.generateAsync({ type: 'blob' })
        FileSaver.saveAs(blob, title + '.zip')
        this.visibleDownloadModal = false
        this.$notification.success({
          message: 'SUCCESS DOWNLOAD ATTACHMENT FILES',
        })
        this.selectedRow = []
        this.loadingAttachmentDownload = false
      } catch (err) {
        this.loadingAttachmentDownload = false
        console.log(err)
      }
    },
    handleChange(payload) {
      const { value, column, innerColumn } = payload
      if (innerColumn) {
        if (!this.detailData[column]) this.detailData[column] = {}
        this.detailData[column][innerColumn] = value
      } else {
        this.detailData[column] = value
      }
      console.log('parent', payload)
      console.log('detailData', this.detailData)
      // const { value, column } = payload
      // // console.log('parent', payload)
      // this.newPending[column] = value
    },
    handleUpdateData() {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to update this student data? this changes will affected to the system</div>
        ),
        onOk: () => {
          this.loadingActionModalManagePending = true
          this.$store.dispatch('master/PUT_STUDENT_PPDB', this.detailData)
            .then(() => {
              this.$notification.success({
                message: 'Success',
                description: 'Student data has been updated',
              })
            })
            .catch(err => {
              console.log(err)
              this.$notification.error({
                message: 'Failed',
                description: 'Process of updating student data has failed. Please try again later',
              })
            })
            .finally(() => {
              this.loadingActionModalManagePending = false
              this.toggleModalManagePending()
              this.fetchDataPending()
            })
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Yes, Update now',
        cancelText: 'Cancel',
      })
    },
    toggleModalManagePending(status, data) {
      this.visibleModalManagePending = !this.visibleModalManagePending
      if (!this.visibleModalManagePending) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
            isDetail: false,
          }
          this.newPending = {
            nama: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : status === 'detail' ? 'isDetail' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newPending = {
            nama: data.nama,
          }
        } else if (status === 'detail') {
          this.detailLoading = true
          this.fetchDetailStudent(data.key)
        }
      }
    },
    handleOkModalManagePending() {
      const content = `${this.status.isEdit ? 'Are you sure want to edit this pending?' : 'Are you sure want to create new pending'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Warning',
          description: 'All fields are required',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            this.loadingActionModalManagePending = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataPending: this.newPending,
            } : {
              dataPending: this.newPending,
            }
            this.$store.dispatch(`master/${this.status.isEdit ? 'EDIT' : 'POST'}_LEVEL`, payload)
              .then(isSuccess => {
                this.loadingActionModalManagePending = false
                this.toggleModalManagePending()
                this.fetchDataPending()
                if (isSuccess) {
                  const description = this.status.isEdit ? 'Pending has been updated' : 'Pending has been created'
                  this.$notification.success({
                    message: 'Success',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? 'Pending has not been updated' : 'Pending has not been created'
                  this.$notification.error({
                    message: 'Failed',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Edit Now' : 'Create Now',
          cancelText: 'Cancel',
        })
      }
    },
    handleTogglePendingAction(innerModal) {
      this.visiblePendingAction = !this.visiblePendingAction

      if (!this.visiblePendingAction) {
        this.titlePendingAction = ''
        this.dataPendingAction = null
        this.status = {
          isDetail: innerModal,
          isApprove: false,
          isReject: false,
        }
        this.newDataPendingAction = {
          nis: null,
          keterangan_ppdb: null,
        }
      }
    },
    handlePendingAction(type, data, innerModal = false) {
      this.titlePendingAction = `Student ${type === 'approve' ? 'Approval' : 'Rejection'} Confirmation`
      this.status[type === 'approve' ? 'isApprove' : type === 'reject' ? 'isReject' : ''] = true
      this.dataPendingAction = data
      this.handleTogglePendingAction(innerModal)
    },
    handleOkPendingAction() {
      const payload = {
        id: this.dataPendingAction.id,
        status_ppdb: this.status.isApprove ? 'accepted' : this.status.isReject ? 'rejected' : 'pending',
        ...this.newDataPendingAction,
      }
      this.$store.dispatch('ppdb/UPDATE_STUDENT_CANDIDATE_STATUS', { payload })
        .then(res => {
          this.$notification.success({
            message: 'Success',
            description: `${this.dataPendingAction.nama} has been ${this.status.isApprove ? 'Approved' : this.status.isReject ? 'Rejected' : ''}`,
          })
        })
        .catch(() => {
          this.$notification.error({
            message: 'Error',
            description: `${this.dataPendingAction.nama} has not been ${this.status.isApprove ? 'Approved' : this.status.isReject ? 'Rejected' : ''}, Please Try Again Later.`,
          })
        })
        .finally(() => {
          this.handleTogglePendingAction()
          this.fetchDataPending()
        })
    },
    fetchDetailStudent(id) {
      this.$store.dispatch('ppdb/FETCH_DETAIL_STUDENT_CANDIDATE', { id })
        .then(res => {
          this.detailLoading = false
          this.detailData = res
        })
    },
    handleSearch(e) {
      this.search = e.target.value
      this.fetchDataPending()
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pendingPagination }
      this.sortBy = sorter.columnKey
      pager.current = pagination.current
      this.pendingPagination = pager
      if (sorter.order === 'ascend') {
        this.order = 'ASC'
      } else if (sorter.order === 'descend') {
        this.order = 'DESC'
      } else {
        this.order = 'ASC'
      }
      this.fetchDataPending()
    },
    fetchDataPending() {
      this.pendingLoadingTable = true
      this.$store.dispatch('ppdb/FETCH_STUDENT_CANDIDATE', { page: this.pendingPagination.current || 1, order: this.order, search: this.search, sortBy: this.sortBy, statusPpdb: 'accepted', tahunMasuk: this.yearBatch ? this.yearBatch.split(' - ')[0] : null })
        .then(res => {
          this.pendingLoadingTable = false
          const pagination = { ...this.pendingPagination }
          pagination.total = res.total
          this.pendingPagination = pagination
          this.pendingDataTable = res.dataTable.map(el => {
            return {
              ...el,
              key: el.id,
            }
          })
        })
    },
    async exportExcel() {
      try {
        const namaInstitusi = this.institusi?.nama?.toUpperCase() || ''
        let title = `DATA PESERTA DIDIK STATUS DITERIMA ${namaInstitusi}`
        const payload = { statusPpdb: 'accepted' }
        if (this.yearBatch?.split(' - ')[0]) {
          title += ` TAHUN ${this.yearBatch.split(' - ')[0]}`
          payload.tahunMasuk = this.yearBatch?.split(' - ')[0]
        }
        this.loadingExport = true
        const allData = await this.$store.dispatch('ppdb/FETCH_ALL_DATA_BY_STATUS', payload)
        await exportDataPpdbMurid(allData, { title })
        this.loadingExport = false
      } catch (err) {
        this.loadingExport = false
        console.log(err)
      }
    },
    async fetchTahunMasuk() {
      try {
        const { data, yearsActive } = await this.$store.dispatch('admin/FETCH_TAHUN_MASUK', { statusPpdb: 'accepted' })
        this.tahunMasuk = data
        if (yearsActive?.id) {
          this.yearBatch = this.tahunMasuk.find(tahun => tahun.tahun === yearsActive.tahun)?.tahun || this.tahunMasuk[0].tahun
        } else {
          this.yearBatch = this.tahunMasuk[0].tahun
        }
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
  async mounted() {
    try {
      await this.fetchTahunMasuk()
      this.fetchDataPending()
    } catch (err) {
      console.log(err)
    }
  },
  computed: {
    isValidateForm() {
      if (this.newPending.nama) {
        return false
      }
      return true
    },
    institusi() {
      return localStorage.institusi ? JSON.parse(localStorage.institusi) : {}
    },
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(selectedRowKeys)
          const newData = selectedRows.filter(row => selectedRowKeys.find(key => key === row.id))
          const oldData = []
          for (let i = 0; i < this.selectedRow.length; i++) {
            const student = this.selectedRow[i]
            const findStudent = newData.find(dat => dat.id === student.id)
            if (!findStudent) {
              const isOnPage = this.pendingDataTable.find(dat => dat.id === student.id)
              if (!isOnPage) oldData.push(student)
            }
          }
          this.selectedRow = oldData.concat(newData)
        },
        selectedRowKeys: this.rowSelectedKey,
      }
    },
    rowSelectedKey() {
      return this.selectedRow.map(row => row.id)
    },
  },
}
</script>

<style>
</style>
